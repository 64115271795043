@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  height: 100%;
}

text{
  margin: 0 auto
}
/* input::-webkit-outer-spin-button {
  padding: 10px;
  margin-top: 20px;
} */
input::-webkit-inner-spin-button {
  margin-top: -8px;
  margin-bottom: -8px;
  padding: 3px;
}
input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}